// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-page-js": () => import("/tmp/29abc3b4/src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-pages-404-js": () => import("/tmp/29abc3b4/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/tmp/29abc3b4/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/tmp/29abc3b4/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("/tmp/29abc3b4/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/29abc3b4/.cache/data.json")

